import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { get, intersection } from 'lodash'
import { navigate } from 'gatsby'

const safeNavigate = (...args) => {
  if (typeof window !== `undefined`) {
    navigate(...args)
  }
}

export default function useVerifyRole(roles, redirectUnauthorized = '/admin/login') {
  const { loading, data: { me = null } = {} } = useQuery(gql`
    query Me {
      me {
        node {
          id
          roles
        }
      }
    }
  `)

  const hasRole = intersection(
    get(me, 'node.roles', []),
    roles,
  ).length > 0

  // Wait for loading to complete before doing anything
  if (loading) {
    return { loading, hasRole: false }
  }

  // User is not signed in
  if (!get(me, 'node.id')) {
    redirectUnauthorized && safeNavigate(redirectUnauthorized)
    return { loading, hasRole: false }
  }

  // Signed does not have one of the roles that is required
  if (!hasRole) {
    safeNavigate('/')
    return { loading, hasRole: false }
  }

  return { loading, hasRole }
}
