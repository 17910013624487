/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { addMonths, format } from 'date-fns'
import { Link } from 'gatsby'

import useVerifyRole from '../../../hooks/verifyRole'
import Hero from '../../../components/Hero'
import Section from '../../../components/Section'
import SEO from '../../../components/SEO'
import { Button, Input, Loader } from '../../../_shared/components'
import { formatPrice } from '../../../lib/format'
import { round } from 'lodash'

const Strong = ({ children }) => (
  <span style={{ fontWeight: 'bold' }}>{children}</span>
)

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])

  const [createSuccess, setCreateSuccess] = useState(false)
  const [pendingCreate, setPendingCreate] = useState(false)
  const [orgnr, setOrgnr] = useState('')
  const [createError, setCreateError] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [vouchers, setVouchers] = useState(1)
  const [months, setMonths] = useState(3)
  const [pricePerVoucher, setPricePerVoucher] = useState(49 * 3)

  const unitPrice = Number.isNaN(pricePerVoucher)
    ? 0
    : Math.round(round(pricePerVoucher / months, 2) * 100)

  const totalPrice = (unitPrice * vouchers * months) / 100

  const {
    data: { organisation = null } = {},
    refetch: refetchOrganisation,
  } = useQuery(
    gql`
      query Organisation($id: String!) {
        organisation(id: $id) {
          id
          name
          ehf
        }
      }
    `,
    { variables: { id: orgnr } },
  )

  const [createInvoice] = useMutation(gql`
    mutation CreateInvoice($invoice: InvoiceData!) {
      createInvoice(invoice: $invoice) {
        invoice {
          id
        }
      }
    }
  `)

  useEffect(() => {
    refetchOrganisation({ id: orgnr })
  }, [refetchOrganisation, orgnr])

  if (loading) {
    return <Loader />
  }

  if (!hasRole) {
    return null
  }

  const isValid =
    name && email && vouchers && months && (organisation?.id || !totalPrice)

  async function handleSubmit(e) {
    e.preventDefault()

    setPendingCreate(true)
    setCreateError(null)

    try {
      const res = await createInvoice({
        variables: {
          invoice: {
            paymentType: 'invoice',
            description: `${vouchers} gavekort à ${months} mnd.`,
            email,
            name,
            organisationId: orgnr,
            lines: [
              {
                sku: 'voucher',
                title: 'Gavekort',
                unitSize: months,
                unitDescription: 'mnd',
                unitPrice,
                quantity: vouchers,
              },
            ],
          },
        },
      })

      if (res?.data?.createInvoice?.invoice?.id) {
        setCreateSuccess(true)
        return
      }

      setCreateError(
        'Rart. Den ble ikke opprettet og det skjedde ikke noe galt',
      )
    } finally {
      setPendingCreate(false)
    }
  }

  return (
    <>
      <SEO title="Gavekort" />
      <Hero
        title={
          <>
            <Link to="/admin">Admin</Link>
            {` > `}
            <Link to="/admin/gavekort">Gavekort</Link>
            {` > `}
            <br />
            Lag faktura
          </>
        }
        type={['center', 'small']}
      />

      {createSuccess ? (
        <Section size="small">
          Hurra! Gavekortene ble laget og blir snart sendt til {email}.
        </Section>
      ) : (
        <Section size="small">
          <form
            onSubmit={handleSubmit}
            style={{ minHeight: 'calc(100vh - 48.3rem)' }}
          >
            <Input
              type="number"
              label="Antall gavekort"
              value={vouchers}
              onChange={v => setVouchers(Number(v))}
              min="1"
            />

            <Input
              type="number"
              label="Måneder pr. gavekort"
              value={months}
              onChange={v => setMonths(Number(v))}
              min="1"
            />

            <Input
              type="number"
              label="Kroner pr. gavekort (eks. MVA)"
              value={pricePerVoucher}
              onChange={v => setPricePerVoucher(parseFloat(v))}
              min="0"
              step="0.01"
            />

            <br />
            <p>Hvem skal gavekortene sendes til?</p>
            <br />

            <Input type="text" label="Navn" value={name} onChange={setName} />

            <Input
              type="text"
              label="E-postadresse"
              value={email}
              onChange={setEmail}
            />

            <br />
            {totalPrice > 0 ? (
              <>
                <p>
                  Hva er organisasjonsnummeret til selskapet som skal få
                  fakturaen?
                </p>
                <br />
                <Input
                  type="text"
                  label="Organisasjonsnummer"
                  value={orgnr}
                  onChange={setOrgnr}
                />
                {orgnr.length >= 9 ? (
                  !organisation ? (
                    'Fant ikke bedriften'
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{organisation.name}</span>{' '}
                      <span>
                        {organisation.ehf ? 'EHF-faktura' : 'E-postfaktura'}
                      </span>
                    </div>
                  )
                ) : null}

                <div
                  style={{
                    marginTop: '2rem',
                    marginBottom: '2rem',
                    borderTop: '3px solid var(--color-highlighted)',
                  }}
                />

                <p>
                  Du er i ferd med å lage en faktura på{' '}
                  <Strong>{formatPrice(totalPrice)} kr</Strong> for{' '}
                  <Strong>{vouchers} gavekort</Strong> à{' '}
                  <Strong>
                    {months} måned{months > 1 ? 'er' : ''}
                  </Strong>
                  . Det tilsvarer en pris pr. måned på {unitPrice / 100} kr.
                </p>

                {email ? (
                  <>
                    <br />
                    <p>
                      Gavekortene blir sendt til <Strong>{email}</Strong>
                      {totalPrice
                        ? ` og fakturaen blir sendt
                ${
                  organisation?.ehf
                    ? `som EHF til ${organisation.name}`
                    : `på epost til samme adresse`
                }`
                        : ''}
                      .
                    </p>
                  </>
                ) : null}
              </>
            ) : (
              <p>
                Du er i ferd med å opprette{' '}
                <Strong>{vouchers} gratis gavekort</Strong> à{' '}
                <Strong>
                  {months} måned{months > 1 ? 'er' : ''}
                </Strong>
                . Gavekortene blir sendt til <Strong>{email}</Strong>.
              </p>
            )}
            <br />
            <br />

            {createError && (
              <>
                {createError}
                <br />
              </>
            )}
            <Button primary type="submit" disabled={!isValid || pendingCreate}>
              Lag og send gavekort{totalPrice ? ' + faktura' : ''}
            </Button>
          </form>
        </Section>
      )}
    </>
  )
}
